

document.querySelectorAll('.js-open-recherche-overhead').forEach($el => {
    $el.addEventListener('click', function() {
        const $search = document.querySelector('.recherche-overhead');
        $search.classList.add('recherche-overhead--active');
       const $input = document.querySelector('.recherche-overhead .wp-block-search__input');
       $input.focus();
       $input.setSelectionRange(0,9999);
    });
});

document.querySelectorAll('.recherche-overhead').forEach($el => {
    const $input = $el.querySelector('.wp-block-search__input');
    const $line = document.createElement("div");
    const $results = $el.querySelector('.recherche-overhead__results');
    const $close = $el.querySelector('.recherche-overhead__close');
    function getTextWidth(input) {

        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");

        const fontWeight = window.getComputedStyle(input, null).getPropertyValue('font-weight') || 'normal';
        const fontSize = window.getComputedStyle(input, null).getPropertyValue('font-size') || '16px';
        const fontFamily = window.getComputedStyle(input, null).getPropertyValue('font-family') || 'Times New Roman';
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`;

        const metrics = context.measureText(input.value);
        return metrics.width;
    }
    function updateSize(hide_results)
    {
        $line.style.width = getTextWidth(this)+'px';

        if (hide_results !== true && this.value.length) {
            $results.classList.remove('recherche-overhead__results--hidden');
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            $results.classList.add('recherche-overhead__results--hidden');
            document.getElementsByTagName('body')[0].style.overflow = 'visible';
        }
    }

    function close()
    {
        $el.classList.remove('recherche-overhead--active');
        $results.classList.add('recherche-overhead__results--hidden');
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }

    $line.classList.add('wp-block-search__line')
    //Envoyer l'attribut pour le container de résultats sur le bon element
    $input.dataset.swpparentel = $el.querySelector('.wp-block-search').dataset.swpparentel;
    $input.after($line);
    updateSize.call($input, true);

    $input.addEventListener('keyup', updateSize);
    $input.addEventListener('change', updateSize);
    $close.addEventListener('click', close);
});
